.mtc-body {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    /* font-family: poppins, sans-serif; */
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
    .mtc-body {
        flex: 0 0 36%;
        max-width: 36%;
    }
}

@media screen and (min-width: 1161px) and (max-width: 1300px) {
    .mtc-body {
        flex: 0 0 39%;
        max-width: 39%;
    }
}

@media screen and (min-width: 1106px) and (max-width: 1161px) {
    .mtc-body {
        flex: 0 0 41%;
        max-width: 41%;
    }
}

@media screen and (min-width: 1006px) and (max-width: 1106px) {
    .mtc-body {
        flex: 0 0 45%;
        max-width: 45%;
    }
}

/* @media screen and (min-width: 992px) and (max-width: 1006px) {
    .mtc-body {
        flex: 0 0 45%;
        max-width: 45%;
    }
} */

@media screen and (min-width: 944px) and (max-width: 1006px) {
    .mtc-body {
        flex: 0 0 48%;
        max-width: 48%;
    }
}

@media screen and (min-width: 500px) and (max-width: 944px) {
    .mtc-body {
        flex: 0 0 80%;
        max-width: 80%;
    }
}

@media screen and (min-width: 362px) and (max-width: 500px) {
    .mtc-body {
        flex: 0 0 90%;
        max-width: 90%;
    }
}

@media screen and (max-width: 362px) {
    .mtc-body {
        flex: 0 0 95%;
        max-width: 95%;
    }
}

.mtc-main {
    background: #fff;
    box-shadow: 0 0 75px rgba(0, 0, 0, .1);
    border-radius: 5px;
    padding: 40px 40px 22px;
    text-align: center;
    /* min-height: 420px; */
    min-height: 350px;
    position: relative;
    margin-bottom: 22px;
}

.mtc-main .mtc-content img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
    margin: auto;
    /* margin: 0 auto 12px; */
}

.mtc-img {
    max-width: 100%;
    border-style: none;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.mtc-content h4 {
    font-weight: 600;
    /* font-size: 22px; */
    font-size: 25px;
    letter-spacing: 0.5px;
    line-height: 33px;
    color: #484848;
    clear: both;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1.33em; */
    margin-block-start: 0.5em;
    /* margin-block-end: 1.33em; */
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}

.mtc-content label {
    font-weight: 500;
    /* font-size: 15px; */
    font-size: 17px;
    line-height: 22px;
    color: #d96122;
    margin-bottom: 12px;
    display: inline-block;
    font-weight: bold;
    letter-spacing: 0.5px;
    cursor: default;
}

.mtc-content p {
    /* font-weight: 400; */
    font-weight: 500;
    font-size: 16px;
    /* font-size: 15px; */
    line-height: 26px;
    text-align: center;
    color: #484848;
    margin-bottom: 1rem;
    margin-top: 0;
    display: block;
    /* font-weight: bold; */
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.mtc-main::after {
    position: absolute;
    content: '';
    width: 45px;
    height: 3px;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 22px;
    background-color: #d96122;
    box-sizing: inherit;
}