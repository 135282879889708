.ajte-body {
    width: 100%;
    margin-top: 100px;
}

.ajte-main {
    width: 90%;
    margin: auto;
}

.ajte-title {
    text-align: center;
}

.ajte-h6 {
    position: relative;
}

.ajte-h6::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    left: 30.5%;
    /* margin-right: -20px; */
}

.ajte-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    margin-left: 10px;
}

@media screen and (min-width: 1500px) {
    .ajte-h6::before {
        left: 37.5%;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
    .ajte-h6::before {
        left: 37%;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .ajte-h6::before {
        left: 36.5%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .ajte-h6::before {
        left: 35%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .ajte-h6::before {
        left: 34%;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1100px) {
    .ajte-h6::before {
        left: 33%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
    .ajte-h6::before {
        left: 32%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
    .ajte-h6::before {
        left: 31%;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .ajte-h6::before {
        left: 30%;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .ajte-h6::before {
        left: 29%;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .ajte-h6::before {
        left: 28%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .ajte-h6::before {
        left: 26%;
    }
}

@media screen and (max-width: 768px) {
    .ajte-h6::before {
        display: none;
    }

    .ajte-h6::after {
        display: none;
    }
}

.ajte-content {
    width: 100%;
    /* display: flex;
    flex-wrap: wrap; */
    gap: 25px;
}

.ajtec {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    gap: 25px;
}

.ajtec-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ajtect-card {
    width: 200px;
    aspect-ratio: 3/2;
    object-fit: contain;
    /* margin: 15px 5px; */
    background-color: #fff;
    border: none;
    /* box-shadow: 2px 2px 10px #9c9c9c; */
}

.ajtect-card a {
    background-color: transparent;
    color: #373737;
    text-decoration: none;
}

/* .ajtect-card:nth-child(odd) {
    border: 1.6px dotted #ff0404;
    box-shadow: 1px 1px 10px #9c9c9c;
} */

.ajtect-card:not(:hover) {
    box-shadow: 2px 2px 10px #9c9c9c;
}

.ajtect-card:hover {
    border-radius: 8px;
    box-shadow: 3px 3px 10px #d96122, -2px -2px 10px #d96122;
    /* box-shadow: 3px 3px 10px #ff0404, -2px -2px 10px #ff0404; */
    transition: box-shadow 0.5s ease;
}

.ajtect-data {
    /* width: 100%; */
    /* height: 100%; */
    padding: 0 10px;
    padding-top: 20px;
    text-align: center;
}

.ajtect-data p {
    color: rgba(72, 72, 72, .8);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

/* .ajtecb-data img {
    width: 50px;
    aspect-ratio: 1/1;
    object-fit: contain;
} */

.ajtec-bottom {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* .ajtecb-card {
    width: 200px;
    aspect-ratio: 3/2;
    object-fit: contain;
    margin: 15px 5px;
    background-color: #fff;
    border: none;
    box-shadow: 2px 2px 10px #9c9c9c;
}

.ajtecb-card a {
    background-color: transparent;
    color: #373737;
    text-decoration: none;
}

.ajtecb-card:nth-child(even) {
    border: 1.6px dotted #ff0404;
    box-shadow: 1px 1px 10px #9c9c9c;
}

.ajtecb-data {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    text-align: center;
}

.ajtecb-data img {
    width: 50px;
    aspect-ratio: 1/1;
    object-fit: contain;
} */