.ocv-body {
    width: 100% !important;
    margin-top: 100px;
}

/* .ocv-body::before {
    position: absolute;
    content: "";
    //top: 4%;
    top: 111.15%;
   // bottom: 42%;
    bottom: -70%;
 //   left: 0;
    width: 40px;
    border: 3px solid #ececec;
    border-right: 0;
    box-sizing: inherit;
} */

/* .ocv-body::after {
    position: absolute;
    content: "";
   // top: 4%;
    top: 111.15%;
   // bottom: 42%;
    bottom: -70%;
 //   right: 0;
    right: 5%;
    width: 40px;
    border: 3px solid #ececec;
    border-left: 0;
} */

/* .ocvb-leftline {
    position: absolute;
    content: "";
    height: 3px;
    //width: 100%;
    width: 90%;
    margin: auto;
    background-color: #ececec;
   // top: 4%;
    left: 50%;
 //   bottom: -65px;
    bottom: -85px;
    transform: translateX(-50%);
    z-index: 0;
    border-radius: 5px;
} */

.ocv-top {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    /* margin-right: -15px; */
    /* margin-left: -15px; */
}

.ocvt-body {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 1.5rem !important;
}

/* @media (min-width: 992px) {
    .ocvt-body {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
} */

.ocvt-main {
    background-color: #fcfcfc;
    position: relative;
    z-index: 3;
    margin-bottom: 45px;
    text-align: center !important;
}

.ocvt-main h3 {
    font-weight: 600;
    font-size: 34px;
    color: #242424;
    margin-bottom: 0;
    line-height: 1.2;
    letter-spacing: 0.5px;
    margin-top: 0;
    display: block;
    /* font-weight: bold; */
    margin-block-start: 1em;
    /* margin-block-end: 1em; */
    margin-block-end: 0.2em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.coreValue-span {
    display: inline-block;
    margin: 5px auto 3px;
    width: 175px;
    height: 2px;
    background: radial-gradient(50% 151250% at 50% -100%, #d96122 0, rgba(255, 255, 255, 0) 100%);
}

.ocv-bottom {
    /* display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; */
    /* width: 83%; */
    width: 90%;
    margin: auto;
    background: linear-gradient(180deg, #fff 0, rgba(255, 255, 255, .4) 100%);
    box-shadow: 0 0 75px 0 #0000001f;
    padding: 45px;
    border-radius: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 740px) and (max-width: 900px) {
    .ocv-bottom {
        padding: 45px 36px;
    }
}

@media screen and (min-width: 600px) and (max-width: 740px) {
    .ocv-bottom {
        padding: 45px 30px;
    }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
    .ocv-bottom {
        padding: 45px 24px;
    }
}

@media screen and (min-width: 350px) and (max-width: 450px) {
    .ocv-bottom {
        padding: 45px 18px;
    }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
    .ocv-bottom {
        padding: 45px 15px;
    }
}

@media screen and (max-width: 300px) {
    .ocv-bottom {
        padding: 45px 5px;
    }
}

.ocvb-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-right: -15px;
    margin-left: -15px; */
    /* margin-right: -15px;
    margin-left: -15px; */
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px; */
    /* width: 100%; */
    /* min-height: 350px; */
    /* min-height: 250px; */
}