.cap-body {
    content-visibility: auto;
    margin: 50px 0;
}

.cap-main {
    max-width: 1440px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin: auto;
}

/* @media screen and (max-width: 1440px) {
    .cap-main {
        max-width: 1240px;
    }
} */

@media screen and (min-width: 1300px) and (max-width: 1466px) {
    .cap-main {
        max-width: 1240px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .cap-main {
        max-width: 1140px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .cap-main {
        max-width: 1060px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
    .cap-main {
        max-width: 960px;
    }
}

@media screen and (max-width: 992px) {
    .cap-main {
        /* max-width: 100%; */
        max-width: 95%;
    }
}

@media screen and (max-width: 575px) {
    .cap-main {
        max-width: 90%;
    }
}

.cap-content {
    background: linear-gradient(93.12deg, #D96122 -10.9%, #D96122 103.3%);
    border-radius: 30px;
    padding: 37px 15px;
    overflow: hidden;
    position: relative;
}

.capc-top {
    margin-left: auto !important;
    margin-right: auto !important;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 768px) {
    .capc-top {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
}

@media (max-width: 768px) {
    .capc-top {
        flex: 0 0 90%;
        max-width: 90%;
    }
}

.capct-main {
    align-items: center !important;
    justify-content: space-between !important;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.capct-left {
    display: block;
}

@media (max-width: 575px) {
    .capct-left {
        margin-bottom: 15px;
    }
}

.capct-left h2 {
    font-weight: 600;
    font-size: 45px;
    color: #fff;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    /* font-size: 1.5em; */
    /* margin-block-start: 0.83em; */
    /* margin-block-end: 0.83em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .capct-left h2 {
        font-size: 30px;
    }
}

.capct-left h6 {
    font-size: 24px;
    font-weight: 200;
    color: #fff;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    /* font-size: 0.67em; */
    /* margin-block-start: 2.33em; */
    /* margin-block-end: 2.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

.capct-right {
    display: block;
}

.capctr-Link {
    border: 2px solid rgba(255, 255, 255, .7);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .3);
    text-decoration: none;
    background: #d96122;
    border-radius: 5px;
    padding: 12px 22px;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    color: #fff;
    /* border: 0; */
    cursor: pointer;
}

.capc-bottom {
    display: block;
}

.capcb-img {
    top: 0;
    left: 0;
    position: absolute;
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
    width: 102px;
    aspect-ratio: auto 102 / 82;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.capcb-img1 {
    bottom: 0;
    left: 40%;
    transform: translateX(-40%);
    position: absolute;
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
    width: 268px;
    aspect-ratio: auto 268 / 134;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.capcb-img2 {
    top: 0;
    right: 0;
    position: absolute;
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
    width: 112px;
    aspect-ratio: auto 112 / 117;
    overflow-clip-margin: content-box;
    overflow: clip;
}