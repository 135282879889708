.fr-body {
    /* background-color: #5b5151; */
}

.ft-main {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* background-color: #5b5151; */
}

.ft-left {
    display: flex;
    flex-direction: column;
}

.ft-container {
    flex: 1 1 300px;
    padding-left: 30px;
}

.fi {
    color: #929292;
    font-size: 20px;
}

.fi a {
    color: #929292;
    font-size: 20px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.fi a:hover {
    color: #d96122;
}

.ft-leftCenter p:hover {
    color: #d96122;
    cursor: pointer;
}

.ft-rightCenter p:hover {
    color: #d96122;
    cursor: pointer;
}

.ft-main-bottom {
    border: none;
    /* border-top: 1px solid #000; */
    /* border-top: 2px solid #f8f8f8; */
    border-top: 2px solid #bab9b9;
}

.ft-main-bottom p {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}

.ft-main-bottom p span {
    color: #d96122;
}

@media screen and (max-width: 360px) {
    .ft-container {
        padding-left: 10px;
    }

    .fi a {
        color: #929292;
        font-size: 20px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        gap: 5px;
    }
}