.cd-body {
    width: 100%;
    padding: 0 0 100px;
    position: relative;
    /* background: linear-gradient(180deg, rgba(196, 196, 196, 0) 59.19%, rgba(217, 97, 34, .25) 100%); */
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 49%, rgba(217, 97, 34, .25) 100%);
    margin: auto;
    margin-bottom: 100px;
}


.cd-main {
    width: 90% !important;
    margin: auto;
}