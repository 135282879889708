.aboutUs {
    font-family: poppins, sans-serif;
}

.aboutUs main {
    /* margin: 100px auto; */
    /* padding-top: 100px; */
}

/* @media (max-width: 768px) {
    .aboutUs main {
        padding-top: 100px
    }
} */