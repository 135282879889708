.whd-body {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.whd-main {
    width: 88%;
    /* height: 200px; */
    margin: auto;
    padding: 15px 20px;
    /* background-color: lightgreen; */
    border: none;
    border-radius: 20px;
    box-shadow: 0 7px 15px 0 rgba(0, 0, 0, .05);
    /* box-shadow: 0px 6px 21px #d8d8d8; */
    margin-bottom: 10px;
    box-sizing: border-box;
}

.whd-main h2 {
    color: #242424;
    font-size: 20px;
}

.whd-main p {
    color: rgba(72, 72, 72, .8);
    font-size: 18px;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .whd-main {
        width: 100%;
        padding: 15px 15px;
    }
}