.desktop-body {
    width: 100%;
}

.desktop-main {
    width: 95%;
    margin: auto;
}

.desktop-mainText {
    border-bottom: 2px solid #F6F6F6;
}

.desktop-mainText h1 {
    text-align: left;
}

.desktop-submain {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.desktop-submain div {
    cursor: pointer;
    text-align: center;
}

.desktop-submain div img {
    width: 100px;
    height: 100px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

@media (max-width: 480px) {
    .desktop-submain {
        width: 80%;
    }

    .desktop-submain div img {
        width: 80px;
        aspect-ratio: 1/1;
        object-fit: contain;
    }
}