.rjel-body {
    width: 100%;
}

.rjel-main {
    width: 90%;
    margin: 80px auto;
}

.rjel-main hr {
    width: 93%;
    margin-left: 5%;
    color: #000;
    background-color: #000;
    border: none;
    border-bottom: 1px solid #000;
}

.rjel-title {
    text-align: center;
}

.rjel-h6 {
    position: relative;
}

.rjel-h6::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    left: 30.5%;
    /* margin-right: -20px; */
}

.rjel-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    margin-left: 10px;
}

@media screen and (min-width: 1500px) {
    .rjel-h6::before {
        left: 37.5%;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
    .rjel-h6::before {
        left: 37%;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .rjel-h6::before {
        left: 36.5%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .rjel-h6::before {
        left: 35%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .rjel-h6::before {
        left: 34%;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1100px) {
    .rjel-h6::before {
        left: 33%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
    .rjel-h6::before {
        left: 32%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
    .rjel-h6::before {
        left: 31%;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .rjel-h6::before {
        left: 30%;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .rjel-h6::before {
        left: 29%;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .rjel-h6::before {
        left: 27.75%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .rjel-h6::before {
        left: 26%;
    }
}

@media screen and (max-width: 768px) {
    .rjel-h6::before {
        display: none;
    }

    .rjel-h6::after {
        display: none;
    }
}

.rjel-content {
    width: 90%;
    margin: auto;
    /* border-bottom: 1px solid #000; */
}

.faq {
    max-width: 600px;
    margin: 0 auto;
}

.question {
    /* margin-bottom: 20px; */
}

.answer {
    display: none;
}

.question.is-revealed .answer {
    transition: 5s ease-in-out;
    padding: 0 20px;
    display: block;
}

.que-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid #000;
    padding: 0 20px;
    cursor: pointer;
}

.que-div button {
    width: auto;
    height: auto;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.rjel-content>*:last-child>.que-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding: 0 20px;
    cursor: pointer;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .que-div {
        width: 85%;
    }

    .rjel-content>*:last-child>.que-div {
        width: 85%;
    }

    .que-div h3 {
        width: 90%;
    }

    .que-div button {
        width: 10%;
    }

    .rjel-main hr {
        /* width: 97.5%; */
        /* width: 114%; */
        /* margin-left: -0.5%; */
        color: #000;
        background-color: #000;
        border: none;
        border-bottom: 1px solid #000;
        margin-left: 0;
    }

    .rjel-content {
        width: 100%;
        margin: auto;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .rjel-main hr {
        width: 97.5%;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .rjel-main hr {
        width: 92%;
    }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
    .rjel-main hr {
        width: 91%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
    .rjel-main hr {
        width: 95%;
    }

    .que-div h3 {
        width: 90%;
    }

    .que-div button {
        width: 10%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .rjel-main hr {
        width: 94%;
    }

    .que-div h3 {
        width: 90%;
    }

    .que-div button {
        width: 10%;
    }
}