.rjfi-body {
    width: 100%;
}

.rjfi-main {
    width: 90%;
    margin: auto;
    margin-top: 50px;
}

.rjfi-title {
    /* width: 70%; */
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.rjfi-h6 {
    position: relative;
}

.rjfi-h6::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    left: 32.8%;
    /* margin-right: -20px; */
}

.rjfi-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    margin-left: 10px;
}

@media screen and (min-width: 1500px) {
    .rjfi-h6::before {
        left: 31%;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1500px) {
    .rjfi-h6::before {
        left: 31.25%;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1450px) {
    .rjfi-h6::before {
        left: 30.5%;
    }
}

@media screen and (min-width: 1350px) and (max-width: 1400px) {
    .rjfi-h6::before {
        left: 29.75%;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
    .rjfi-h6::before {
        left: 29%;
    }
}

@media screen and (min-width: 1250px) and (max-width: 1300px) {
    .rjfi-h6::before {
        left: 28%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
    .rjfi-h6::before {
        left: 27.25%;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
    .rjfi-h6::before {
        left: 26.25%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1150px) {
    .rjfi-h6::before {
        left: 25.5%;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1100px) {
    .rjfi-h6::before {
        left: 24%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
    .rjfi-h6::before {
        left: 22.5%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
    .rjfi-h6::before {
        left: 21%;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .rjfi-h6::before {
        left: 19%;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .rjfi-h6::before {
        left: 18%;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .rjfi-h6::before {
        left: 16%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .rjfi-h6::before {
        left: 14%;
    }
}

@media screen and (max-width: 768px) {
    .rjfi-h6::before {
        display: none;
    }

    .rjfi-h6::after {
        display: none;
    }
}

.rjfi-title h1 {
    font-size: 36px;
}

.rjfi-title h1 span {
    color: #d96122;
}

.rjfi-title p {
    color: #2b2d2d;
    font-size: 20px;
}

.rjfi-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}