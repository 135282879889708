.ajf-body {
    width: 100%;
    margin-bottom: 60px;
}

.ajf-main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.ajf-img {
    width: 600px;
    /* width: 50%; */
    height: 400px;
    margin-top: 80px;
}

.ajf-img img {
    width: 100%;
    height: 100%;
}

.ajf-content {
    width: 600px;
    /* width: 40%; */
    margin-top: 50px;
}

.ajfc-h6 {
    position: relative;
}

.ajfc-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    margin-top: 5px;
    /* top: 5%; */
    /* top: 50%; */
    margin-left: 10px;
}

.ajf-content h1 {
    margin-top: -20px;
}

.ajf-content h1 span {
    color: #d96122;
}

.ajf-content p {
    font-size: 17.75px;
    color: #77828b;
    margin-top: -10px;
    line-height: 28px;
    display: block;
}

.ajf-content button {
    cursor: pointer;
    padding: 18px 20px;
    font-size: 17px;
    margin-top: 5px;
    color: #ffffff;
    background-color: #d96122;
    border: none;
    border-radius: 8px;
}

@media screen and (max-width:768px) {
    .ajf-img {
        width: 100%;
    }

    .ajf-content {
        width: 100%;
        margin-top: 0px;
    }

    .ajfc-h6::after {
        display: none;
    }
}

@media screen and (max-width:155px) {
    .ajfc-h4::after {
        display: none;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .ajf-img {
        display: none;
    }

    .ajf-content {
        width: 90%;
    }
}