.ajs-body {
    width: 100%;
}

.ajs-main {
    width: 90%;
    margin: auto;
}

.ajs-top {
    text-align: center;
}

.ajst-h6 {
    position: relative;
}

.ajst-h6::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    left: 30.5%;
    /* margin-right: -20px; */
}

.ajst-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 50%;
    margin-left: 10px;
}

@media screen and (min-width: 1400px) and (max-width: 1500px) {
    .ajst-h6::before {
        left: 29.75%;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
    .ajst-h6::before {
        left: 28.25%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .ajst-h6::before {
        left: 26%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .ajst-h6::before {
        left: 24%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .ajst-h6::before {
        left: 21.5%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
    .ajst-h6::before {
        left: 19.75%;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .ajst-h6::before {
        left: 18%;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .ajst-h6::before {
        left: 16.25%;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .ajst-h6::before {
        left: 14.5%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .ajst-h6::before {
        left: 13%;
    }
}

@media screen and (max-width: 768px) {
    .ajst-h6::before {
        display: none;
    }

    .ajst-h6::after {
        display: none;
    }
}

/* @media screen and (max-width:900px) {
    .ajst-h6::before {
        display: none;
    }

    .ajst-h6::after {
        display: none;
    }
} */

.ajs-top h1 {
    margin-top: -18px;
}

.ajs-top h1 span {
    color: #d96122;
}

.ajs-top p {
    color: #323232;
    margin-top: -8px;
    font-size: 20px;
}

.ajs-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 50px auto 0;
}

.ajs-btn {
    width: auto;
    margin: auto;
    /* margin: 50px auto; */
}

.ajs-btn button {
    /* width: 100%; */
    margin: 50px 36%;
    /* margin: 50px 460px; */
    padding: 15px 22px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    background-color: #d96122;
    border: none;
    border-radius: 12.5px;
}

@media screen and (max-width:942px) {
    .ajs-btn {
        width: 100%;
    }

    .ajs-btn button {
        width: 80%;
        margin: 50px auto;
    }
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .ajs-btn {
        width: 100%;
    }

    .ajs-btn button {
        width: 100%;
        margin: 50px 0;
    }
}