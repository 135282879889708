.cu-body {
    width: 95%;
    margin: 0 auto 50px;
    display: flex;
    flex-wrap: wrap;
    font-family: "poppins", sans-serif;
}

.cu-left {
    position: relative;
    width: 50%;
}

.cu-left-main {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(193, 89, 36, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    /* text-align: center; */
}

.content {
    /* padding: 30px 100px; */
    padding: 30px 70px;
}

.content h1 {
    /* text-align: center; */
    font-size: 50px;
}

.content1 {
    padding: 0px 10px 0px 10px;
}

.content1 h2 {
    font-size: 36px;
}

.content1 p {
    font-size: 20px;
}

#main-image {
    width: 100%;
    height: 100%;
}

.cu-right {
    width: 40%;
    padding: 50px;
    height: 100%;
    max-height: 711px;
    margin: auto 0;
    box-shadow: 0px 5px 18px #888888, 0px 2px 50px #888888;
}

.cu-right .cu-right-top h1 {
    text-align: center;
}

.cu-right-content form {
    margin: auto;
}

.cu-right-content form label {
    margin-bottom: 10px;
}

.cu-right-content form input {
    width: 100%;
    padding: 15px 0px 20px 20px;
    outline: none;
    /* margin-top: 10px; */
    font-size: 15px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid black;
}

.cu-right-content form textarea {
    width: 100%;
    padding: 15px 0px 20px 20px;
    outline: none;
    font-size: 15px;
    border: none;
    border-bottom: 1px solid black;
}

.cu-right-content form button {
    color: #ffffff;
    background-color: #d96122;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 10px;
}

/* @media screen and (max-width: 768px) {
    .cu-body {
        flex-direction: column;
        align-items: stretch;
    }

    .cu-left,
    .cu-right {
        width: 100%;
    }
} */

@media screen and (min-width: 300px) and (max-width: 768px) {
    .cu-body {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        margin: 0;
    }

    .cu-left {
        width: 100%;
        height: 100%;
        /* aspect-ratio: 7/2; */
    }

    /* .content {
        padding: 15px 50px;
    }

    .content h1 {
        font-size: 35px;
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        font-size: 25px;
    }

    .content1 p {
        font-size: 12px;
    } */

    /* .cu-right {
        width: 80%;
        height: 100%;
        padding: 30px;
    } */

    .cu-right {
        width: 80%;
        padding: 20px;
    }

    .cu-right-content form input {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
}

/* @media screen and (min-width: 400px) and (max-width: 768px) {
    .cu-right {
        width: 80%;
        padding: 20px;
    }

    .cu-right-content form input {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .cu-right {
        width: 80%;
        padding: 20px;
    }

    .cu-right-content {
        width: 95%;
    }

    .cu-right-content form input {
        width: 90%;
     //   padding: 15px 0px 20px 20px;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
} */

@media screen and (min-width: 768px) and (max-width: 900px) {
    .cu-body {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        margin: 0;
    }

    .cu-left {
        width: 100%;
        height: 100%;
    }

    .cu-right {
        width: 93%;
        height: 100%;
        padding: 30px;
    }

    .cu-right-content form input {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
}

@media screen and (min-width: 900px) and (max-width: 1100px) {
    .cu-body {
        width: 95%;
        /* flex-direction: column; */
        /* align-items: stretch; */
        margin: auto;
    }

    .cu-left {
        width: 45%;
        height: 100%;
        margin: 10% 0;
    }

    .cu-right {
        width: 45%;
        height: 100%;
        padding: 30px;
    }

    .cu-right-content form input {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1253px) {
    .cu-body {
        width: 95%;
        /* flex-direction: column; */
        /* align-items: stretch; */
        margin: auto;
    }

    .cu-left {
        width: 45%;
        height: 100%;
        margin: 10% 0;
    }

    .cu-right {
        width: 45%;
        height: 100%;
        padding: 30px;
    }

    .cu-right-content form input {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        margin-bottom: 10px;
        border: none;
        border-bottom: 1px solid black;
    }

    .cu-right-content form textarea {
        width: 90%;
        padding: 15px 0px 20px 20px;
        outline: none;
        font-size: 15px;
        border: none;
        border-bottom: 1px solid black;
    }
}

@media screen and (min-width: 300px) and (max-width: 325px) {
    .cu-right {
        width: 80%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 325px) and (max-width: 350px) {
    .cu-right {
        width: 81.5%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 350px) and (max-width: 375px) {
    .cu-right {
        width: 83%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 375px) and (max-width: 400px) {
    .cu-right {
        width: 84%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
    .cu-right {
        width: 85%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 450px) and (max-width: 500px) {
    .cu-right {
        width: 87.5%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
    .cu-right {
        width: 88.5%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 550px) and (max-width: 600px) {
    .cu-right {
        width: 90%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 600px) and (max-width: 650px) {
    .cu-right {
        width: 91%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 650px) and (max-width: 700px) {
    .cu-right {
        width: 91.5%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 700px) and (max-width: 768px) {
    .cu-right {
        width: 92%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .cu-right {
        width: 92%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .cu-right {
        width: 92.5%;
        height: 100%;
        padding: 30px;
    }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
    .content {
        padding: 15px 50px;
    }

    .content h1 {
        font-size: 35px;
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        font-size: 25px;
    }

    .content1 p {
        font-size: 12px;
    }
}

@media screen and (min-width: 400px) and (max-width: 490px) {
    .content {
        padding: 15px 50px;
    }

    .content h1 {
        font-size: 40px;
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        font-size: 27px;
    }

    .content1 p {
        font-size: 15px;
    }
}

@media screen and (min-width: 490px) and (max-width: 700px) {
    .content {
        padding: 15px 50px;
    }

    .content h1 {
        font-size: 45px;
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        font-size: 33px;
    }

    .content1 p {
        font-size: 17px;
    }
}

@media screen and (min-width: 700px) and (max-width: 850px) {
    .content {
        padding: 15px 50px;
    }

    .content h1 {
        font-size: 50px;
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        font-size: 36px;
    }

    .content1 p {
        font-size: 20px;
    }
}

@media screen and (min-width: 850px) and (max-width: 1053px) {
    .content {
        padding: 15px 50px;
    }

    .content h1 {
        /* font-size: 50px; */
    }

    .content1 {
        padding: 0px 5px 0px 5px;
    }

    .content1 h2 {
        /* font-size: 35px; */
    }

    .content1 p {
        /* font-size: 24px; */
    }
}