.tw-body {
    max-width: 1440px;
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
    margin-right: auto;
    margin-left: auto;
    font-family: poppins, sans-serif;
    /* background-color: #7E3C31; */
}

.tw-main {
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    /* padding-right: 15px;
    padding-left: 15px; */
    padding-right: 7.55px;
    padding-left: 7.5px;
    /* margin-right: -15px;
    margin-left: -15px; */
}

.tw-left {
    flex: 0 0 50%;
    max-width: 50%;
    /* flex: 0 0 45%;
    max-width: 45%; */
    position: relative;
    width: 100%;
    /* padding-right: 15px;
    padding-left: 15px; */
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 992px) {
    .tw-main {
        /* padding-left: 0;
        padding-right: 0; */
        /* padding-left: 10px; */
        /* padding-right: 10px; */
    }

    .tw-left {
        /* flex: 0 0 100%;
        max-width: 100%; */
        flex: 0 0 90%;
        max-width: 90%;
        /* padding-left: 0;
        padding-right: 0; */
        /* padding-left: 15px;
        padding-right: 15px; */
    }
}

@media screen and (min-width: 850px) and (max-width: 992px) {
    .tw-main {
        padding-left: 10px;
    }
}

@media screen and (max-width: 850px) {
    .tw-main {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.twl-top {
    margin-bottom: 1.5rem !important;
}

.twlt-main {
    position: relative;
    margin-bottom: 15px;
}

.twlt-main h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 1.2;
    color: #242424;
    padding-top: 30px;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    /* margin-block-start: 1.33em; */
    /* margin-block-end: 1.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.twlt-main h4::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.twlt-main h4::after {
    position: absolute;
    content: '';
    width: 80%;
    bottom: 5px;
    left: 0;
    background: rgba(217, 97, 34, .25);
    height: 11px;
    box-sizing: inherit;
}

@media (max-width: 528px) {
    .twlt-main h4::after {
        position: absolute;
        content: '';
        /* width: 46%; */
        width: 220px;
        bottom: 5px;
        left: 0;
        background: rgba(217, 97, 34, .25);
        height: 11px;
        box-sizing: inherit;
    }
}

.twl-top p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #484848;
    margin-top: 0;
    margin-bottom: 15px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.twl-top p::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.twl-bottom {
    /* margin-bottom: 1.5rem !important; */
}

.twlb-main {
    position: relative;
    margin-bottom: 15px;
}

.twlb-main h4 {
    font-weight: 600;
    font-size: 42px;
    line-height: 1.2;
    color: #242424;
    padding-top: 30px;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    /* margin-block-start: 1.33em; */
    /* margin-block-end: 1.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.twlb-main h4::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.twlb-main h4::after {
    position: absolute;
    content: '';
    width: 80%;
    bottom: 5px;
    left: 0;
    background: rgba(217, 97, 34, .25);
    height: 11px;
    box-sizing: inherit;
}

@media (max-width: 503px) {
    .twlb-main h4::after {
        position: absolute;
        content: '';
        /* width: 80%; */
        width: 220px;
        bottom: 5px;
        left: 0;
        background: rgba(217, 97, 34, .25);
        height: 11px;
        box-sizing: inherit;
    }
}

.twl-bottom p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #484848;
    margin-top: 0;
    margin-bottom: 15px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.twl-bottom p::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.tw-right {
    margin-left: auto !important;
    margin-right: auto !important;
    /* flex: 0 0 33.333333%; */
    /* max-width: 33.333333%; */
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media screen and (min-width: 993px) {
    .tw-right {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-left: 0px;
        padding-right: 0px;
    }

    .twr-div {
        flex: 0 0 40%;
        max-width: 40%;
    }
}

@media screen and (max-width: 992px) {
    .tw-right {
        /* flex: 0 0 100%; */
        /* max-width: 100%; */
    }

    .twr-main {
        /* display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-right: 0px;
        margin-left: 0px; */
    }

    .twr-div {
        /* flex: 0 0 20%; */
        /* max-width: 20%; */
        /* padding-right: 10px; */
        /* padding-left: 10px; */
        /* box-sizing: border-box; */
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .twr-main {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-right: 0px;
        margin-left: 0px;
    }

    .twr-div {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media screen and (max-width: 768px) {
    .twr-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-right: 0px;
        margin-left: 0px;
    }

    .twr-div {
        flex: 0 0 40%;
        max-width: 40%;
        padding-left: 0px;
        padding-right: 0px;
    }
}

.twr-main {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px;
    margin-left: -15px; */
}

.twr-div {
    /* flex: 0 0 50%; */
    /* flex: 0 0 40%; */
    /* max-width: 50%; */
    /* max-width: 40%; */
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.twrd-div {
    text-align: center;
    margin-bottom: 50px;
}

.twrd-div img {
    object-fit: contain;
    /* filter: drop-shadow(0px 10px 15px #E09334); */
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
    width: 65px;
    aspect-ratio: auto 65 / 65;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.twrd-div img::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.Professionals-img {
    filter: drop-shadow(0px 10px 15px #E09334);
}

.Projects-img {
    filter: drop-shadow(0px 10px 15px #7E3C31);
}

.Countries-img {
    filter: drop-shadow(0px 10px 15px #D0843F);
}

.YearsOfExperience-img {
    filter: drop-shadow(0px 10px 15px rgba(178, 42, 51, .6));
}

.twrd-div h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 61px;
    color: #d96122;
    margin-top: 0;
    margin-bottom: .5rem;
    display: block;
    /* font-size: 1.5em; */
    /* margin-block-start: 0.83em; */
    /* margin-block-end: 0.83em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

.twrd-div h2::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}

.twrd-div h3 {
    /* font-weight: 400; */
    font-weight: 550;
    font-size: 17px;
    line-height: 24px;
    color: #d96122;
    margin-top: 0;
    margin-bottom: .5rem;
    display: block;
    /* font-size: 1.17em; */
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

.twrd-div h3::selection {
    color: #fff;
    background: #da6427;
    -webkit-text-fill-color: #fff;
}