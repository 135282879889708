.cdo-body {
    width: 100%;
    font-family: "Poppins", sans-serif !important;
}

.cdo-h3 {
    font-weight: 600;
    font-size: 28px;
    color: #000;
    line-height: 1.2;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    /* font-size: 1.17em; */
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media only screen and (max-width: 767px) {
    .cdo-h3 {
        font-size: 20px;
    }
}

.cdoc-main {
    padding: 20px;
    margin-top: 1.5rem !important;
    position: relative;
    /* display: -ms-flexbox; */
    display: flex;
    /* -ms-flex-direction: column; */
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

@media (max-width: 575px) {
    .cdoc-main {
        padding: 15px;
    }
}

.cdocm-top {
    background-color: #fff;
    padding: 0 20px 0 20px;
    border-bottom: 0;
    /* border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; */
    margin-bottom: 0;
}

.cdocm-top:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

@media (max-width: 575px) {
    .cdocm-top {
        padding: 0;
    }
}

.cdocmt-h4 {
    color: #242424;
    font-size: 1.5rem;
    margin-bottom: .5rem;
    /* font-weight: 500; */
    font-weight: 600;
    line-height: 1.2;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1.33em; */
    /* margin-block-end: 1.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmt-h4 {
        font-size: 16px;
    }
}

.cdocmt-main {
    padding-top: .5rem !important;
    align-items: center !important;
    justify-content: space-between !important;
    display: flex !important;
}

@media (max-width: 400px) {
    .cdocmt-main {
        flex-wrap: wrap !important;
        gap: 10px;
    }
}

.cdocmtm-left {
    align-items: center !important;
    display: flex !important;
}

@media (max-width: 575px) {
    .cdocmtm-left {
        margin-right: 5px;
    }
}

.cdocmtml-img {
    width: 27px;
    height: 27px;
    aspect-ratio: auto 24 / 24;
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}

@media (max-width: 575px) {
    .cdocmtml-img {
        width: 20px;
        height: 20px;
    }
}

.cdocmtml-div {
    margin-left: 6px;
}

@media (max-width: 575px) {
    .cdocmtml-div {
        margin-left: 3px;
    }
}

.cdocmtmld-p {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #C4C4C4;
    margin-bottom: 5px;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmld-p {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.cdocmtmld-h6 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #242424;
    margin-top: 0;
    display: block;
    /* font-size: 0.67em; */
    /* margin-block-start: 2.33em; */
    /* margin-block-end: 2.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmld-h6 {
        font-size: 9px;
        margin-bottom: 0;
    }
}

.cdocmtm-center {
    align-items: center !important;
    display: flex !important;
}

@media (max-width: 575px) {
    .cdocmtm-center {
        margin-right: 5px;
    }
}

.cdocmtmc-img {
    width: 27px;
    height: 27px;
    aspect-ratio: auto 24 / 24;
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}

@media (max-width: 575px) {
    .cdocmtmc-img {
        width: 20px;
        height: 20px;
    }
}

.cdocmtmc-div {
    margin-left: 6px;
}

@media (max-width: 575px) {
    .cdocmtmc-div {
        margin-left: 3px;
    }
}

.cdocmtmcd-p {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #C4C4C4;
    margin-bottom: 5px;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmcd-p {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.cdocmtmcd-h6 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #242424;
    margin-top: 0;
    display: block;
    /* font-size: 0.67em; */
    /* margin-block-start: 2.33em; */
    /* margin-block-end: 2.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmcd-h6 {
        font-size: 9px;
        margin-bottom: 0;
    }
}

.cdocmtm-right {
    align-items: center !important;
    display: flex !important;
}

@media (max-width: 575px) {
    .cdocmtm-right {
        margin-right: 5px;
    }
}

.cdocmtmr-img {
    width: 27px;
    height: 27px;
    aspect-ratio: auto 24 / 24;
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
    overflow-clip-margin: content-box;
    overflow: clip;
}

@media (max-width: 575px) {
    .cdocmtmr-img {
        width: 20px;
        height: 20px;
    }
}

.cdocmtmr-div {
    margin-left: 6px;
}

@media (max-width: 575px) {
    .cdocmtmr-div {
        margin-left: 3px;
    }
}

.cdocmtmrd-p {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #C4C4C4;
    margin-bottom: 5px;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmrd-p {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.cdocmtmrd-h6 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    color: #242424;
    margin-top: 0;
    display: block;
    /* font-size: 0.67em; */
    /* margin-block-start: 2.33em; */
    /* margin-block-end: 2.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmtmrd-h6 {
        font-size: 9px;
        margin-bottom: 0;
    }
}

.cdomtm-btn {
    cursor: pointer;
    border: 1px solid #D96122;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: #D96122;
    background-color: #fff;
    font-family: "Poppins", sans-serif !important;
    /* background: #e6e6e6; */
    line-height: 24px;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
    margin: 0;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    /* padding-block: 1px; */
    /* padding-inline: 6px; */
}

.cdomtm-btn:hover {
    border-color: #ccc #bbb #aaa;
}

@media (max-width: 575px) {
    .cdomtm-btn {
        padding: 8px 10px;
        font-size: 10px;
    }
}

.cdocm-bottom {
    display: block;
}

.cdocmb-body {
    padding: 1.25rem;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, .125);
    background: transparent;
}

@media (max-width: 575px) {
    .cdocmb-body {
        padding: 12px 10px;
    }
}

.cdocmb-main {
    display: block;
}

.cdocmbm-p {
    color: rgba(72, 72, 72, .8);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 1rem;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media only screen and (max-width: 767px) {
    .cdocmbm-p {
        font-size: 15px;
        line-height: 26px;
    }
}

@media (max-width: 575px) {
    .cdocmbm-p {
        font-size: 13px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 375px) {
    .cdocmbm-p {
        font-size: 12px;
        line-height: 18px;
    }
}

.cdocmbm-p1 {
    color: rgba(72, 72, 72, .8);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 1rem;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media only screen and (max-width: 767px) {
    .cdocmbm-p1 {
        font-size: 15px;
        line-height: 26px;
    }
}

@media (max-width: 575px) {
    .cdocmbm-p1 {
        font-size: 13px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 375px) {
    .cdocmbm-p1 {
        font-size: 12px;
        line-height: 18px;
    }
}

.cdocmbm-h4 {
    font-weight: 500;
    padding: 6px 0;
    font-size: 18px;
    line-height: 1.2;
    color: #D96122;
    margin-bottom: .5rem;
    margin-top: 0;
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmbm-h4 {
        padding: 3px 0;
        font-size: 15px;
    }
}

@media only screen and (max-width: 375px) {
    .cdocmbm-h4 {
        font-size: 15px;
    }
}

.cdocmbm-top {
    display: flex;
    /* -ms-flex-wrap: wrap; */
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cdocmbmt-main {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.cdocmbmtm-ul {
    margin: 0;
    padding: 0;
    list-style: disc;
    display: block;
    /* list-style-type: disc; */
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* padding-inline-start: 40px; */
    unicode-bidi: isolate;
}

@media (max-width: 991px) {
    .cdocmbmtm-ul {
        margin: 0 !important;
    }
}

.cdocmbmtm-li {
    position: relative;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 17px;
    padding-top: 3px;
    padding-left: 25px;
    line-height: 24px;
    color: #000;
    list-style: none;
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
}

@media (max-width: 575px) {
    .cdocmbmtm-li {
        font-size: 13px;
    }
}

@media only screen and (max-width: 375px) {
    .cdocmbmtm-li {
        font-size: 11px;
        margin-bottom: 10px;
    }
}

.cdocmbmtm-li::before {
    position: absolute;
    content: url("../../../images/tick-bullet.svg");
    left: 0;
    width: 100%;
    vertical-align: middle;
    height: 100%;
    z-index: 1;
    box-sizing: inherit;
}

.cdocmbm-bottom {
    text-align: right !important;
    margin-top: 1rem !important;
}

.cdocmbmb-link {
    cursor: pointer;
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    transition: 800ms;
    font-size: 14px;
    font-weight: 600;
    padding: 18px 58px;
    background: #d85b1a;
    color: #fff;
    border-color: #d85b1a;
    text-decoration: none;
    border-radius: 5px;
    /* font-size: 18px; */
    /* padding: 16px 40px 16px 30px; */
    line-height: 1;
    position: relative;
    border: 0;
    text-transform: capitalize;
    display: inline-block;
    /* font-weight: 400; */
    /* color: #212529; */
    text-align: center;
    vertical-align: middle;
    /* -webkit-user-select: none; */
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (max-width: 575px) {
    .cdocmbmb-link {
        font-size: 13px;
        padding: 13px 30px;
    }
}