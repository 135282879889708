.hs-body {
    width: 100%;
    height: 94.5vh;
    position: relative;
    /* padding-top: 150px; */
    /* padding-top: 20px; */
    padding-bottom: 20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(196, 196, 196, 0) .01%, rgba(217, 97, 34, .2) 100%);
}

.hs-main {
    width: 100%;
    z-index: 1;
}

.hs-body::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(https://taglineinfotech.com/wp-content/themes/tagline/assets/images/main-head.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding-top: 150px; */
    padding-top: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 200px;
    z-index: -1;
    /* padding-top: 150px; */
}

.hs-content {
    width: 70%;
    margin: auto;
    text-align: center;
}

.hs-content h1 {
    font-size: 60px;
}

.hs-content h1 span {
    color: transparent;
    background-color: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(268.59deg, #d96122 7.52%, #d80041 92.78%);
    /* background-image: linear-gradient(268deg, #d96122 8%, #d80041 92%); */
    /* background-image: linear-gradient(to right, #ee0b9b, #d96122); */
}

.hs-content p {
    width: 50%;
    margin: auto;
    margin-bottom: 50px;
}

/* .hs-content a {
    background-color: #d96122;
//    background-color: #fb9004;
    color: #fff;
    padding: 15px 30px;
    font-size: 20px;
    text-decoration: none;
    border: none;
    border-radius: 10px;
} */

.hsc-link {
    width: 100%;
    color: #fff;
    text-decoration: none;
    /* width: 100px; */
}

.hs-content .hsc-div {
    width: 175px;
    margin: auto;
    background-color: #d96122;
    color: #fff;
    padding: 15px 30px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
}

@media screen and (min-width: 786px) and (max-width: 1090px) {
    .hs-content {
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .hs-content h1 {
        font-size: 50px;
    }

    .hs-content h1 span {
        color: transparent;
        background-color: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(268.59deg, #d96122 7.52%, #d80041 92.78%);
    }

    .hs-content p {
        width: 90%;
        margin: auto;
        margin-bottom: 40px;
    }

    /* .hs-content a {
        background-color: #d96122;
        color: #fff;
        padding: 12px 20px;
        font-size: 16px;
        text-decoration: none;
        border: none;
        border-radius: 10px;
    } */
    .hsc-link {
        text-decoration: none;
    }

    .hs-content .hsc-div {
        background-color: #d96122;
        color: #fff;
        padding: 12px 20px;
        font-size: 16px;
        text-decoration: none;
        border: none;
        border-radius: 10px;
    }
}

@media screen and (max-width: 786px) {
    .hs-body {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 0px;
        padding-bottom: 20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, rgba(196, 196, 196, 0) .01%, rgba(217, 97, 34, .2) 100%);
    }

    .hs-main {
        width: 100%;
        z-index: 1;
    }

    .hs-body::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-image: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        left: 0;
        right: 0;
        bottom: 200px;
        z-index: -1;
    }

    .hs-content {
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .hs-content h1 {
        font-size: 30px;
    }

    .hs-content h1 span {
        color: transparent;
        background-color: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(268.59deg, #d96122 7.52%, #d80041 92.78%);
    }

    .hs-content p {
        width: 90%;
        margin: auto;
        margin-bottom: 30px;
    }

    /* .hs-content a {
        background-color: #d96122;
        color: #fff;
        padding: 8px 15px;
        font-size: 12px;
        text-decoration: none;
        border: none;
        border-radius: 10px;
    } */

    .hsc-link {
        text-decoration: none;
    }

    .hs-content .hsc-div {
        background-color: #d96122;
        color: #fff;
        padding: 8px 15px;
        font-size: 12px;
        text-decoration: none;
        border: none;
        border-radius: 10px;
    }
}

/* Base styles */
/* body {
    margin: 0;
    font-family: Arial, sans-serif;
  } */
/* 
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: transparent;
    margin-top: -150px;
    margin-bottom: 150px;
}

.logo h1 {
    color: #f57224;
    margin: 0;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav-links li {
    position: relative;
}

.nav-links li a {
    text-decoration: none;
    color: black;
}

.quote-button button {
    background-color: #f57224;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
}

.quote-button button:hover {
    background-color: #d9601e;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 160px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .quote-button {
        display: none;
    }
}

@media (min-width: 769px) {
    .nav-links {
        display: flex;
    }
} */