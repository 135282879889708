.aje-body {
    /* display: flex; */
    width: 100%;
    margin-bottom: 100px;
    margin-top: 30px;
}

.aje-main {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* @media screen and (min-width: 500px) {
    .rje-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .rje-title {
        position: sticky;
        top: 0;
        height: 100%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .rje-content {
        flex-grow: 1;
        flex: 0 0 50%;
        max-width: 50%;
    }
} */

.aje-title {
    position: sticky;
    top: 0;
    height: 100%;
    /* width: 40%; */
    /* height: 300px;
    width: 500px;
    background-color: lightblue; */
    /* padding: 20px; */
    flex: 0 0 50%;
    max-width: 50%;
}

.aje-h6 {
    position: relative;
}

.aje-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    margin-top: 5px;
    /* top: 5%; */
    /* top: 50%; */
    margin-left: 10px;
}

@media screen and (max-width: 768px) {
    .aje-h6::after {
        display: none;
    }
}

.aje-title h1 {
    padding-right: 10px;
    font-size: 32px;
}

.aje-title h1 span {
    color: #d96122;
}

.aje-title p {
    color: rgba(72, 72, 72, .8);
    font-size: 18px;
    /* padding: 5px 0 !important; */
    /* padding-right: 25px; */
    line-height: 26px;
    text-align: justify;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.aje-title p span {
    color: #d96122;
}

.aje-title button {
    cursor: pointer;
    padding: 15px 20px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #d96122;
}

.aje-content {
    flex-grow: 1;
    flex: 0 0 50%;
    max-width: 50%;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .aje-main {
        display: block;
    }

    .aje-title {
        position: static;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .aje-content {
        /* position: relative; */
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .aje-h6::after {
        display: none;
    }
}