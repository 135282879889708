.msp-body {
    width: 100%;
}

.msp-main {
    width: 95%;
    margin: auto;
}

.msp-title {
    text-align: center;
}

.msp-content {
    width: 95%;
    margin: auto;
    margin-top: 80px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    /* justify-content: space-between; */
}

.msp-div {
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;
    width: 370px;
    min-height: 400px;
}

.msp-div1 {
    /* width: 30%; */
    /* width: 400px;
    min-height: 400px;
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px; */
    /* box-shadow: 1px 1px 1px #000, -1px -1px 1px #000; */
}

.msp-div1 p {
    font-size: 50px;
    margin: auto;
    position: absolute;
    top: 10%;
    left: 5%;
    transform: translate(-10%, -5%);
}

.msp-div1 p span {
    font-size: 56px;
    font-weight: 600;
    color: #242424;
    position: relative;
}

.msp-div1 p span span::before {
    content: "";
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12px;
    background: rgba(217, 97, 34, .25);
    /* background-image: linear-gradient(to right, #685b6e, #605562); */
}

.msp-div2 {
    /* width: 30%; */
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 0 0 75px rgba(0, 0, 0, .1);
    /* justify-content: space-between; */
    /* background: #fff;
    box-shadow: 0 0 75px rgba(0, 0, 0, .1);
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;
    width: 400px;
    min-height: 400px; */
    /* display: flex; */
    /* align-items: flex-start; */
    /* flex-direction: column; */
}

.mspc-top {
    display: flex !important;
    align-items: center !important;
    /* flex-wrap: wrap; */
    /* justify-content: space-between; */
}

.mspc-top img {
    width: 65px;
    aspect-ratio: 1;
    /* height: 65px; */
    /* max-width: 100%; */
    /* width: 40px; */
    /* aspect-ratio: 1; */
    margin-right: 20px;
    vertical-align: middle;
    filter: drop-shadow(0px 5px 15px #93BAD3);
}

.mspc-top h6 {
    /* width: 80%; */
    margin-top: -10px;
    font-size: 22px;
    margin-bottom: 0.5rem;
    display: block;
    /* font-size: 0.67em; */
    /* margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */
}

.mspc-des {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #484848;
    margin: 27px auto;
}

.mspc-btn {
    width: 100%;
}

.mspc-btn a {
    /* color: #93BAD3; */
    margin-top: auto;
    font-weight: 700;
    font-size: 18px;
    text-decoration: none;
    color: #d96122;
    background-color: transparent;
}

.msp-div3 {
    background-color: #d96122;
    position: relative;
    overflow: hidden;
}

.msp-div3 h6 {
    font-weight: 400;
    font-size: 34px;
    line-height: 51px;
    color: #fff;
    display: block;
    /* font-size: 0.67em; */
    margin-block-start: 2.33em;
    margin-block-end: 2.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
}

.msp-div3 h6 span {
    font-weight: 600;
}

.msp-div3 img {
    position: absolute;
    top: -100px;
    right: 0;
    width: 348px;
    aspect-ratio: auto 348 / 324;
    overflow-clip-margin: content-box;
    overflow: clip;
}

/* .msp-div3 a {
    display: inline-block;
    background: rgba(255, 195, 164, .5);
    box-shadow: 0 0 75px rgba(0, 0, 0, .1);
    border-radius: 5px;
    color: #fff;
    padding: 15px 30px;
    margin-top: 80px;
    text-decoration: none;
    transition: all ease-in-out .5s;
} */

.mspd-link {
    text-decoration: none;
    cursor: pointer;
}

.mspdl-btn {
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    background: rgba(255, 195, 164, .5);
    border: none;
    box-shadow: 0 0 75px rgba(0, 0, 0, .1);
    border-radius: 5px;
    color: #fff;
    padding: 15px 30px;
    margin-top: 80px;
    text-decoration: none;
    transition: all ease-in-out .5s;
}

/* @media screen and (min-width: 963px) {
    .msp-div1 {}
} */

/* @media screen and (max-width: 786px) { */
@media screen and (max-width: 964px) {
    .msp-div1 {
        margin-bottom: -250px;
    }

    .msp-div1 p {
        font-size: 30px;
        margin: auto;
        position: absolute;
        top: 0%;
        left: 5%;
        transform: translate(0%, -5%);
    }

    .msp-div1 p span {
        font-size: 35px;
        font-weight: 600;
        color: #242424;
        position: relative;
    }

    .msp-div1 p span span::before {
        content: "";
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 9px;
        background: rgba(217, 97, 34, .25);
    }
}