.rjse-body {
    width: 100%;
    font-family: poppins, sans-serif;
    /* margin-bottom: 30px; */
    margin-bottom: 50px;
}

.rjse-main {
    width: 90%;
    margin: auto;
}

.rjse-title {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.rjse-h6 {
    position: relative;
}

.rjse-h6::before {
    position: absolute;
    content: '';
    background: linear-gradient(to left, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 47%;
    left: 30.5%;
    /* margin-right: -20px; */
}

.rjse-h6::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #d96122, #d9612200);
    width: 98px;
    height: 2px;
    top: 47%;
    margin-left: 10px;
}

@media screen and (min-width: 1500px) {
    .rjse-h6::before {
        left: 37.75%;
    }
}

@media screen and (min-width: 1450px) and (max-width: 1500px) {
    .rjse-h6::before {
        left: 38%;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1450px) {
    .rjse-h6::before {
        left: 37.5%;
    }
}

@media screen and (min-width: 1350px) and (max-width: 1400px) {
    .rjse-h6::before {
        left: 37%;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1350px) {
    .rjse-h6::before {
        left: 36.5%;
    }
}

@media screen and (min-width: 1250px) and (max-width: 1300px) {
    .rjse-h6::before {
        left: 36%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1250px) {
    .rjse-h6::before {
        left: 35.5%;
    }
}

@media screen and (min-width: 1150px) and (max-width: 1200px) {
    .rjse-h6::before {
        left: 35%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1150px) {
    .rjse-h6::before {
        left: 34%;
    }
}

@media screen and (min-width: 1050px) and (max-width: 1100px) {
    .rjse-h6::before {
        left: 33.5%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1050px) {
    .rjse-h6::before {
        left: 32.5%;
    }
}

@media screen and (min-width: 950px) and (max-width: 1000px) {
    .rjse-h6::before {
        left: 31.5%;
    }
}

@media screen and (min-width: 900px) and (max-width: 950px) {
    .rjse-h6::before {
        left: 30.5%;
    }
}

@media screen and (min-width: 850px) and (max-width: 900px) {
    .rjse-h6::before {
        left: 29.25%;
    }
}

@media screen and (min-width: 800px) and (max-width: 850px) {
    .rjse-h6::before {
        left: 28.25%;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .rjse-h6::before {
        left: 27.5%;
    }
}

@media screen and (max-width: 768px) {
    .rjse-h6::before {
        display: none;
    }

    .rjse-h6::after {
        display: none;
    }
}

.rjse-content {
    width: 100%;
}

.rjse-table {
    width: 100%;
    border-collapse: collapse;
    /* overflow-x: hidden; */
}

.rjse-table thead {
    background-color: rgba(217, 97, 34, .3);
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.rjse-table thead tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.rjse-table thead tr th {
    display: table-cell;
    vertical-align: inherit;
    color: #fff;
    background-color: #d96122;
    padding: 20px 10px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    border-right: 1px solid #fff;
}

.rjse-table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    /* background-color: #d96122; */
}

.rjse-table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    /* background-color: #22cdd9; */
}

.rjse-table tbody tr:nth-child(odd) {
    background: #fff;
}

.rjse-table tbody tr:nth-child(even) {
    background: #f5f5f6;
    /* background-color: rgba(241, 241, 241, .5); */
    /* background: #c5c3c2; */
}

.rjse-table tbody tr td {
    width: 25%;
    /* font-size: 19px; */
    font-size: 18px;
    font-weight: 400;
    color: #3f2d24;
    text-align: center;
    padding: 20px 10px;
    /* border-right: 1px solid rgba(36, 36, 36, .1); */
    display: table-cell;
    vertical-align: inherit;
}

.rjse-table tbody tr td:not(:last-child) {
    border-right: 1px solid #f1f1f1;
    /* border-right: 1px solid rgba(36, 36, 36, .1); */
}

.rjse-table tbody tr:nth-child(odd) td:nth-child(3) {
    background: #f7dfd3;
    /* background: rgba(217, 97, 34, .2); */
}

.rjse-table tbody tr:nth-child(even) td:nth-child(3) {
    background: #facbb2;
    /* background: rgba(255, 162, 109, .5); */
}

.rjse-btn {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.rjse-btn button {
    cursor: pointer;
    padding: 6px 20px;
    font-size: 15px;
    border: none;
    border-radius: 6px;
    color: #fff;
    background-color: #d96122;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .rjse-content {
        /* overflow-x: auto; */
    }

    .rjse-table {
        display: block;
        overflow-x: auto;
        min-width: 300px;
        white-space: nowrap;
    }
}