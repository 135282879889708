.dev-body {
    margin: auto;
    /* width: 90%; */
}

.dev-main {
    width: 100%;
}

.dev-top {
    margin-bottom: 2%;
    text-align: center;
}

.dev-span {
    display: inline-block;
    width: 175px;
    height: 2px;
    margin: auto;
    background: radial-gradient(50% 151250% at 50% -100%, #d96122 0, rgba(255, 255, 255, 0) 100%);
}

.dev-bottom {
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    gap: 20px;
}

.devb-top {
    /* display: flex; */
    gap: 20px;
}

.devbt-top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    gap: 20px;
    margin-bottom: 20px;
}

.devbtt-div1 {
    padding: 5px 20px 10px 20px;
    border: 1px solid #000000;
}

.devbtt-div2 {
    padding: 5px 20px 10px 20px;
    border: 1px solid #000000;
}

.devbttd-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}

.devbttd-main a {
    color: #000000;
    text-decoration: none;
}

.devbt-bottom {
    padding: 5px 20px 20px 20px;
    border: 1px solid #000000;
    text-align: center;
}

.devbtb-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
}

.devbtb-main a {
    color: #000000;
    text-decoration: none;
}

.devb-bottom {
    width: 30%;
}

.devb-bottom img {
    width: 100%;
}