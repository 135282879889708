.tech-body {
    width: 100%;
    min-height: 100vh;
    /* Use min-height instead of height for better responsiveness */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.tech-main {
    width: 100%;
    height: auto;
}

.tech-mainText {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
}

.tech-mainText h1 {
    text-align: center;
    font-size: 36px;
    /* Scalable font size */
}

.tech-span {
    display: block;
    width: 175px;
    height: 2px;
    margin: auto;
    margin-top: -10px;
    background: radial-gradient(50% 151250% at 50% -100%, #d96122 0, rgba(255, 255, 255, 0) 100%);
}

.tech-submain {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 0 60px rgba(0, 0, 0, .11);
}

.button-container {
    width: 27%;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
    border-right: 5px solid #d96122;
}

.button-container button {
    width: 100%;
    border: none;
    font-size: 25px;
    background-color: #ffffff;
    padding: 10px 0;
    /* Added padding for better touch area */
}

.button-container button:hover {
    color: #d96122;
    background-color: #efefef;
    border-left: 5px solid #d96122;
}

.content-container {
    width: 70%;
    padding: 20px 0;
}

/* Responsive Styles */
@media screen and (min-width: 1300px) {
    .tech-submain {
        height: 585px;
    }

    .button-container {
        height: 100%;
    }

    .content-container {
        height: 100%;
    }
}

@media screen and (max-width: 1300px) and (min-width: 1032px) {
    .tech-submain {
        min-height: 585px;
    }

    .button-container {
        min-height: 100%;
    }

    .content-container {
        height: 100%;
    }
}

@media (max-width: 1032px) {
    .tech-body {}

    .tech-mainText h1 {
        font-size: 30px;
    }

    .tech-submain {
        flex-direction: column;
    }

    .button-container {
        width: 100%;
        border-right: none;
        border-bottom: 5px solid #d96122;
        margin-bottom: 20px;
    }

    .content-container {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .tech-mainText h1 {
        font-size: 24px;
        /* Adjust font size for mobile */
    }

    .button-container button {
        font-size: 20px;
        /* Reduce button font size for smaller screens */
        padding: 8px 0;
        /* Adjust padding for smaller buttons */
    }

    .tech-span {
        width: 120px;
    }
}

@media (max-width: 480px) {
    .tech-mainText h1 {
        font-size: 20px;
        /* Further reduce font size for very small screens */
    }

    .tech-span {
        width: 100px;
    }

    .button-container button {
        font-size: 18px;
        /* Adjust for mobile */
        padding: 6px 0;
    }
}