/* .rjsc-card {
    width: 400px;
//    width: 25%;
    margin-top: 100px;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  //  border: 1px solid #000;
    padding: 10px 12px 32px;
    text-align: center;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 4px 12px 0 rgba(54, 46, 46, 0.25);
//    box-shadow: 2px 4px 12px 0 rgba(215, 215, 215, .25);
}

.rjsc-card::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(116.57% 116.57% at 50% 50%, #d96122 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    padding: 2px 0 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.rjsc-card h3 {
    width: 100%;
    text-align: center;
}

.rjsc-card p {
    font-size: 16px;
    line-height: 30px;
}

.rjsc-img {
    width: 70px;
    height: 70px;
    padding: 26px;
    border-radius: 50%;
    position: relative;
    margin-top: -80px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    z-index: 1;
}

.rjsc-img::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  //  background: radial-gradient(116.57% 116.57% at 50% 50%, #d96122 0, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(#ecb69d, #ecb69d) content-box;
    padding-bottom: calc(100% - 75%);
    transition: .5s;
//    -webkit-mask: radial-gradient(farthest-side, transparent calc(99.8% - var(--b)), #fff calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, transparent calc(99.8% - 2px), #fff calc(100% - 2px));
}

.rjsc-img img {
    z-index: 20;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width:942px) {
    .rjsc-card {
        width: 100%;
    }
} */



.rjsc-card {
    position: relative;
    width: 100%;
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    /* box-shadow: 2px 4px 12px 0 rgba(54, 46, 46, 0.25); */
}

@media (min-width: 768px) {
    .rjsc-card {
        /* flex: 0 0 50%;
        max-width: 50%; */
        flex: 0 0 45%;
        max-width: 45%;
    }
}

@media (min-width: 1200px) {
    .rjsc-card {
        /* flex: 0 0 33.333333%;
        max-width: 33.333333%; */
        flex: 0 0 30%;
        max-width: 30%;
    }
}

/* @media screen and (max-width: 1600px) {
    .rjsc-card {
        min-height: 230px;
    }

    .rjscc-body {
        min-height: 100%;
    }

    .rjscc-main {
        min-height: 100%;
    }

    .rjscc-submain {
        min-height: 330px;
    }
} */

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .rjscc-submain {
        min-height: 260px;
    }
}

/* @media screen and (min-width: 1200px) and (max-width: 1265px) {
    .rjscc-submain {
        min-height: 260px;
    }
} */

@media screen and (min-width: 972px) and (max-width: 1200px) {
    .rjscc-submain {
        min-height: 225px;
    }
}

@media screen and (min-width: 880px) and (max-width: 972px) {
    .rjscc-submain {
        min-height: 300px;
    }
}

@media screen and (min-width: 768px) and (max-width: 880px) {
    .rjscc-submain {
        min-height: 275px;
    }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    .rjsc-card {
        width: 85%;
        margin: auto;
    }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
    .rjsc-card {
        width: 90%;
        margin: auto;
    }
}

@media screen and (min-width: 300px) and (max-width: 450px) {
    .rjsc-card {
        width: 95%;
        margin: auto;
    }
}

.rjscc-body {
    margin: 0;
    /* padding-top: 70px; */
    padding-top: 110px;
    height: 100%;
    padding-bottom: 30px;
    position: relative;
}

.rjscc-main {
    height: 100%;
    transition: all .5s;
}

.rjscc-submain {
    /* box-shadow: 2px 4px 12px 0 rgba(215, 215, 215, .25); */
    box-shadow: 2px 4px 12px 0 rgba(54, 46, 46, 0.25);
    text-align: center;
    border-radius: 10px;
    position: relative;
    padding: 10px 12px 32px;
    /* z-index: 99; */
    z-index: 9;
    height: 100%;
    background: #fff;
}

.rjscc-submain::before(:hover) {
    padding: 2px;
}

.rjscc-submain::before {
    content: "";
    position: absolute;
    inset: 0;
    background: radial-gradient(116.57% 116.57% at 50% 50%, #d96122 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    padding: 2px 0 0;
    /* padding: 2px; */
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
    transition: all .5s ease;
}

.rjscc-img {
    --fill: 92%;
    --b: 2px;
    width: 100px;
    height: 100px;
    padding: 26px;
    border-radius: 50%;
    position: relative;
    /* margin-top: -80px; */
    margin-top: -110px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    z-index: 0;
}

.rjscc-img::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* bottom: 36px; */
    border-radius: 50%;
    background: radial-gradient(116.57% 116.57% at 50% 50%, #d96122 0, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(#ecb69d, #ecb69d) content-box;
    padding-bottom: calc(100% - var(--fill));
    transition: .5s;
    -webkit-mask: radial-gradient(farthest-side, transparent calc(99.8% - var(--b)), #fff calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, transparent calc(99.8% - var(--b)), #fff calc(100% - var(--b)));
}

.rjscci-img {
    max-width: 100%;
    height: auto;
    border-style: none;
    vertical-align: middle;
    /* width: 45px;
    aspect-ratio: auto 45 / 45; */
    width: 60px;
    aspect-ratio: auto 60 / 60;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.rjscc-h3 {
    font-size: 20px;
    line-height: normal;
    color: #242424;
    margin-bottom: .5rem;
    font-weight: 500;
    margin-top: 0;
    display: block;
    /* font-size: 1.17em; */
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    /* font-weight: bold; */
    unicode-bidi: isolate;
}

@media (max-width: 1440px) {
    .rjscc-h3 {
        font-size: 18px;
    }
}

.rjscc-p {
    font-size: 18px;
    line-height: 30px;
    color: rgba(72, 72, 72, .8);
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 0;
    display: block;
    /* margin-block-start: 1em; */
    /* margin-block-end: 1em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

@media (max-width: 1440px) {
    .rjscc-p {
        font-size: 16px;
        line-height: 30px;
    }
}