.rjfi-body {
    width: 100%;
}

.rjfi-main {
    width: 90%;
    margin: auto;
    margin-top: 50px;
}

.rjfi-title {
    /* width: 70%; */
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
}

.rjfi-title h1 {
    font-size: 36px;
}

.rjfi-title h1 span {
    color: #d96122;
}

.rjfi-title p {
    color: #2b2d2d;
    font-size: 20px;
}

.rjfi-content {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}